<template>
  <div>
    <div class="accept">
      <div class="text-center read-acccept justify-content-center" no-body>
        <div class="content-accept">
          <div class="head">
            <img src="../../assets/images/logo.png" alt="" height="50px" />
            <h5 class="mt-4">
              <strong>Convite de prestação de serviços</strong>
            </h5>
            <p class="mx-5">
              Sua empresa foi convidada para para participar de um BID de
              prestação de serviços:
            </p>
          </div>
          <div
            class="body"
            v-if="status && status.object && status.object.homologation"
          >
            <h5>
              <strong>{{ status.object.homologation.name }}</strong>
            </h5>
            <p class="mx-4">
              <strong>
                {{ status.object.homologation.borrower.social_reason }}
              </strong>
              representada
              {{
                status.object.homologation.borrower.default_responsible.is_male
                  ? "pelo senhor"
                  : "pela senhora"
              }}
              <strong>
                {{
                  status.object.homologation.borrower.default_responsible.name
                }}</strong
              >
              Atuante no segmento de
              <strong> {{ status.object.scope }}</strong
              >, em
              <strong
                >{{ status.object.homologation.city.name }} -
                {{ status.object.homologation.state.name }},</strong
              >
              para o período de
              <strong>
                {{ status.object.date_init | date }}
              </strong>
              à
              <strong> {{ status.object.date_end | date }} </strong>, por meio
              desta o convida para prestação de:
            </p>
            <div class="scope text-left">
              <div class="row">
                <div class="col-8">
                  <p>
                    <strong>{{ status.object.scope }}:</strong>
                  </p>
                  <ul>
                    <li
                      v-for="(service, index) in status.object.services"
                      :key="index"
                    >
                      {{ service.code }} - {{ service.activity }}
                    </li>
                  </ul>
                </div>
                <div class="col-4">
                  <p><strong>Período do contrato</strong></p>
                  <p>
                    {{ status.object.homologation.date_init | date }} à
                    {{ status.object.homologation.date_end | date }}
                  </p>
                </div>
              </div>
              <hr />
              <div class="row">
                <small class="col-4"
                  >Valor mensal: R$
                  {{ status.object.contract_value_mensal | money }}</small
                >
                <small class="col-4"
                  >Faturamento mensal: R$
                  {{ status.object.monthly_invoicing_above | money }}</small
                >
                <small class="col-4"
                  >Limite de dependência:
                  {{ status.object.dependency_limit_perc }}%</small
                >
              </div>
            </div>
          </div>
          <div class="after-decline pt-5" v-if="isDeclined">
            <div class="head text-center">
              <h5 class="mt-4">
                <strong>Convite não disponível.</strong>
              </h5>
              <p class="mx-5">
                Parece que o convite foi recusado ou está expirado
              </p>
              <b-button
                @click="$router.back()"
                variant="primary"
                class="mt-2 mb-4 w-100 w-md-auto"
              >
                Voltar
              </b-button>
            </div>
          </div>
          <div class="actions mt-5" v-else>
            <b-button variant="primary" v-b-modal.accept-homologation>
              Aceitar convite
            </b-button>
            <br />
            <b-button
              variant="link"
              class="text-muted"
              v-b-modal.decline-homologation
            >
              Recusar convite
            </b-button>
          </div>
        </div>

        <b-modal id="decline-homologation" size="sm" centered>
          <template #modal-header> <h5>Atenção!</h5></template>
          <p>
            Você está prestes a recusar uma homologação, essa ação não poderá
            ser desfeita, deseja continuar?
          </p>
          <template #modal-footer="{ close }">
            <b-button variant="link" @click="handleDeclineInvite()"
              >Recusar convite</b-button
            >
            <b-button variant="primary" @click="close()">Cancelar</b-button>
          </template>
        </b-modal>
        <b-modal id="accept-homologation" size="sm" centered>
          <template #modal-header> <h5>Atenção!</h5></template>
          <p>
            Ao aceitar a homologação você será pedido para cadastrar uma senha,
            deseja continuar?
          </p>
          <template #modal-footer="{ close }">
            <b-button variant="link" @click="close()" :disabled="loading"
              >Cancelar</b-button
            >
            <b-button
              variant="primary"
              @click="handleAcceptHomologation()"
              :disabled="loading"
              >Continuar</b-button
            >
          </template>
        </b-modal>
      </div>
      <footer>
        <p>Maat © 2021 | Todos os direitos reservados</p>
      </footer>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      key: null,
      isDeclined: true,
      loading: false,
      homologation: {},
      status: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions([
      "get_homologation",
      "get_status_notification",
      "accept_homologation",
    ]),
    getHomologation() {
      this.get_homologation({ id: this.user.pending_homologations[0] }).then(
        (response) => {
          this.homologation = response;
        }
      );
    },
    handleDeclineInvite() {
      this.$router.push(`/invite-homologation/decline/?key=${this.key}`);
    },

    async getStatusHomologation() {
      this.status = await this.get_status_notification({ key: this.key });
      this.isDeclined = this.status.status !== 1;
    },
    handleAcceptHomologation() {
      this.loading = true;
      let key = this.key;
      this.accept_homologation({ key })
        .then((data) => {
          this.loading = false;
          const { user } = data;
          if (user.force_password_change) {
            this.$router.push(`/auth/change/?key=${key}`);
            return;
          } else if (user.has_pending_inbound_process) {
            this.$router.push("/inbound-process/");
            return;
          } else {
            this.$router.push("/main/dashboard");
            return;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    document.body.style.background = "var(--bg)";
  },
  beforeDestroy() {
    document.body.style = "";
  },
  mounted() {
    this.key = this.$route.query.key;
    this.getStatusHomologation();
  },
};
</script>
<style lang="scss" scoped>
.accept {
  color: $text-color;
  max-width: 700px;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  .read-acccept {
    background: $bg-1;
    border: 1px solid var(--border-color);
    overflow: hidden;
    padding: 3em 0 0;
    display: flex;
    margin: 2em 0;
    min-height: calc(100% - 7em);
    max-width: 800px;
    .content-accept {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      display: flex;
      min-height: 100%;
    }
  }
  .scope {
    border: 1px solid $border-color;
    padding: 1em;
    margin: 3em 1em 0;
    hr {
      border-top-color: $border-color;
    }
  }
}
.after-decline {
  padding: 0;
  @media (max-width: $break-md) {
    height: 100vh;
    padding: 0 3em;
    display: flex;
    align-items: center;
    background: $bg-1;
    img {
      width: 200px;
    }
  }
}
</style>
